import { Modal, ModalHeader, ModalMain, Stack, Text } from '@sixfold/common-ui';
import React, { useEffect, useState } from 'react';

import './easter_eggs.css';

// Easter egg emojis - only using egg emojis as requested
export const easterEggEmojis = ['🥚', '🥚', '🥚', '🥚', '🥚', '🥚', '🥚', '🥚', '🥚', '🥚'];

// Easter egg types/animations
export const easterEggTypes = [
  'rotate',
  'bounce',
  'flash',
  'shake',
  'flip',
  'pulse',
  'wiggle',
  'spin',
  'float',
  'grow',
];

// Color themes for eggs
export const eggColors = [
  '#ff6b6b',
  '#48dbfb',
  '#1dd1a1',
  '#feca57',
  '#ff9ff3',
  '#54a0ff',
  '#5f27cd',
  '#576574',
  '#ff9f43',
  '#10ac84',
];

// Local storage key for found eggs
const STORAGE_KEY = 'sf_april_fools_found_eggs';

// Helper function to load found eggs from localStorage
export const loadFoundEggs = (): number[] => {
  const storedEggs = localStorage.getItem(STORAGE_KEY);
  if (storedEggs) {
    try {
      const parsedEggs = JSON.parse(storedEggs);
      if (Array.isArray(parsedEggs)) {
        return parsedEggs;
      }
    } catch (e) {
      console.error('Error parsing stored eggs', e);
      localStorage.removeItem(STORAGE_KEY);
    }
  }
  return [];
};

// Helper function to save found eggs to localStorage
export const saveFoundEgg = (eggId: number): number[] => {
  const currentEggs = loadFoundEggs();
  if (!currentEggs.includes(eggId)) {
    const newFoundEggs = [...currentEggs, eggId];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newFoundEggs));
    return newFoundEggs;
  }
  return currentEggs;
};

interface EasterEggProps {
  id: number;
  position?: { top: string; left: string } | 'random';
  style?: React.CSSProperties;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export const EasterEgg: React.FC<EasterEggProps> = ({
  id,
  position = 'random',
  style = {},
  className = '',
  size = 'medium',
}) => {
  const [found, setFound] = useState(false);
  const emoji = easterEggEmojis[id % easterEggEmojis.length];
  const animationType = easterEggTypes[id % easterEggTypes.length];
  const color = eggColors[id % eggColors.length];

  // Check if this egg was already found
  useEffect(() => {
    const foundEggs = loadFoundEggs();
    if (foundEggs.includes(id)) {
      setFound(true);
    }
  }, [id]);

  // Generate random position if not provided
  const eggPosition =
    position === 'random'
      ? {
          top: `${Math.floor(Math.random() * 80) + 10}%`,
          left: `${Math.floor(Math.random() * 80) + 10}%`,
        }
      : position;

  // Map size to font size
  const sizeMap = {
    small: '28px',
    medium: '38px',
    large: '48px',
  };

  const handleClick = () => {
    saveFoundEgg(id);
    setFound(true);

    // Trigger a custom event so other components can react to egg being found
    window.dispatchEvent(new CustomEvent('eggFound', { detail: { id } }));
  };

  if (found) return null;

  return (
    <div
      className={`easter-egg ${animationType} ${className}`}
      style={{
        ...eggPosition,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: sizeMap[size],
        textShadow: `0 0 10px ${color}, 0 0 20px ${color}`,
        background: 'transparent',
        position: 'absolute',
        ...style,
      }}
      onClick={handleClick}>
      {emoji}
    </div>
  );
};

export const EasterEggCounter: React.FC = () => {
  const [foundCount, setFoundCount] = useState(0);
  const totalEggs = 10; // You can adjust this based on how many eggs you've placed

  useEffect(() => {
    // Initialize count from localStorage
    setFoundCount(loadFoundEggs().length);

    // Listen for egg found events
    const handleEggFound = () => {
      setFoundCount(loadFoundEggs().length);
    };

    window.addEventListener('eggFound', handleEggFound);
    return () => {
      window.removeEventListener('eggFound', handleEggFound);
    };
  }, []);

  return (
    <div className="easter-egg-counter">
      <span>
        {foundCount} / {totalEggs} Eggs Found
      </span>
    </div>
  );
};

export const EasterEggReveal: React.FC = () => {
  const [foundAll, setFoundAll] = useState(false);
  const totalEggs = 10; // Should match the number in EasterEggCounter

  useEffect(() => {
    // Check if all eggs are found
    const checkFoundAll = () => {
      const foundEggs = loadFoundEggs();
      setFoundAll(foundEggs.length >= totalEggs);
    };

    // Check initially
    checkFoundAll();

    // Listen for new eggs found
    const handleEggFound = () => {
      checkFoundAll();
    };

    window.addEventListener('eggFound', handleEggFound);
    return () => {
      window.removeEventListener('eggFound', handleEggFound);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (foundAll) {
      setIsOpen(true);
    }
  }, [foundAll]);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!foundAll) return null;

  return (
    <Modal open={isOpen} onToggle={(next) => !next && handleClose()} trigger={<span style={{ display: 'none' }} />}>
      <ModalHeader>Happy April Fools Day!</ModalHeader>
      <ModalMain>
        <Stack direction="column" style={{ padding: '20px', textAlign: 'center' }}>
          <Text>You found all the Easter eggs! 🎉</Text>
          <Text>Have a great day!</Text>
        </Stack>
      </ModalMain>
    </Modal>
  );
};
