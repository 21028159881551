import { useQuery } from '@sixfold/app-data-framework';
import { useMemo } from 'react';

import { IsPresentOnTransporeonPlatformQuery, IsPresentOnTransporeonPlatformQueryVariables } from '../../lib/graphql';
import { isPresentOnTransporeonPlatform as isPresentOnTransporeonPlatformQuery } from '../graphql';

export function useIsPresentOnTransporeonPlatform(
  companyId: string,
): { isPresentOnTransporeonPlatform: boolean; loading: false } | { loading: true } {
  const { data, loading } = useQuery<IsPresentOnTransporeonPlatformQuery, IsPresentOnTransporeonPlatformQueryVariables>(
    isPresentOnTransporeonPlatformQuery,
    { variables: { companyId }, fetchPolicy: 'cache-first' },
  );

  return useMemo(() => {
    if (loading) {
      return { loading };
    }

    return {
      loading,
      isPresentOnTransporeonPlatform: data?.viewer?.company?.isPresentOnTransporeonPlatform ?? false,
    };
  }, [loading, data?.viewer?.company?.isPresentOnTransporeonPlatform]);
}
