import { useQuery } from '@apollo/client';
import React from 'react';

import { useViewerCompany } from './containers/hooks';
import { companyUsers, USERS_TABLE_PAGE_SIZE } from './graphql';
import {
  CompanyUsersQuery as CompanyUsersResult,
  CompanyUsersQueryVariables,
  getNodes,
  loadMoreFromConnection,
} from '../lib/graphql';

export function useUsersInCompany() {
  const {
    company: { company_id },
  } = useViewerCompany();
  const result = useQuery<CompanyUsersResult, CompanyUsersQueryVariables>(companyUsers, {
    variables: { companyId: company_id, first: USERS_TABLE_PAGE_SIZE },
  });

  const loadNextPage = React.useCallback(async () => {
    await loadMoreFromConnection(result, ['viewer', 'company', 'users', 'users'], USERS_TABLE_PAGE_SIZE);
  }, [result]);

  if (result.error !== undefined) {
    throw result.error;
  }

  const isLoading = result.loading;
  const users = getNodes(result.data?.viewer?.company?.users.users);

  return {
    isLoading,
    queryResult: users,
    countResult: result.data?.viewer?.company?.users.count ?? 0,
    isPresentOnTpPlatform: result.data?.viewer?.company?.isPresentOnTransporeonPlatform ?? false,
    isShipper: result.data?.viewer?.company?.is_shipper ?? false,
    hasNextPage: result.data?.viewer?.company?.users.users.pageInfo.hasNextPage ?? false,
    loadNextPage,
  };
}
