import { useAnalytics } from '@sixfold/app-analytics-framework';
import {
  LanguagePicker as CommonUiLanguagePicker,
  LanguagePickerItem,
  LanguagePickerItemData,
} from '@sixfold/common-ui';
import { Localized, i18n, useLanguage, useLocalizations } from '@sixfold/localization-component';
import * as React from 'react';

import { EmbedConfig } from '../../../shared/types';
import { CustomerSupportAvailableLanguage } from '../../customer_support_ticket/components/PlatformSupportLanguageSelect';
import { EmbedDataContext } from '../embed_data_context';

export const supportedLanguages = {
  bg: 'Български',
  cs: 'Čeština',
  de: 'Deutsch',
  en: 'English',
  'en-US': 'English (US)',
  es: 'Español',
  fr: 'Français',
  hr: 'Hrvatski',
  it: 'Italiano',
  hu: 'Magyar',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'Pусский',
  sk: 'Slovenčina',
  fi: 'Suomi',
  sv: 'Svenska',
  tr: 'Türkçe',
  uk: 'Українська',
};

export type AvailableLanguage = keyof typeof supportedLanguages;
export const availableLanguages = Object.keys(supportedLanguages);

export const getNotificationApiSupportedLanguages = (
  notificationApiSupportedLanguages?: EmbedConfig['notification_api_supported_languages'],
) => {
  return Object.keys(supportedLanguages).filter(
    (languageKey) =>
      notificationApiSupportedLanguages === undefined || notificationApiSupportedLanguages.includes(languageKey),
  );
};

export const useLanguagePickerLocalizations = (): {
  [key in AvailableLanguage | CustomerSupportAvailableLanguage as `languagePicker.language.${key}`]: string;
} => {
  return useLocalizations({
    'languagePicker.language.bg': 'Bulgarian',
    'languagePicker.language.cs': 'Czech',
    'languagePicker.language.de': 'German',
    'languagePicker.language.en': 'English',
    'languagePicker.language.en-US': 'English (US)',
    'languagePicker.language.es': 'Spanish',
    'languagePicker.language.fr': 'French',
    'languagePicker.language.hr': 'Croatian',
    'languagePicker.language.it': 'Italian',
    'languagePicker.language.hu': 'Hungarian',
    'languagePicker.language.nl': 'Dutch',
    'languagePicker.language.pl': 'Polish',
    'languagePicker.language.pt': 'Portuguese',
    'languagePicker.language.ro': 'Romanian',
    'languagePicker.language.ru': 'Russian',
    'languagePicker.language.sk': 'Slovak',
    'languagePicker.language.fi': 'Finnish',
    'languagePicker.language.sv': 'Swedish',
    'languagePicker.language.tr': 'Turkish',
    'languagePicker.language.uk': 'Ukrainian',
    'languagePicker.language.sr': 'Serbian',
    'languagePicker.language.sl': 'Slovenian',
  });
};

interface Props {
  changeLocaleMutation?: (locale: string) => Promise<void>;
  trigger?: ({
    formattedLabel,
    selectedItem,
  }: {
    label: React.ReactNode;
    formattedLabel: React.ReactNode;
    selectedItem: LanguagePickerItemData | null;
  }) => React.ReactElement;
}

export const LanguagePicker: React.FunctionComponent<Props> = ({ changeLocaleMutation, trigger }) => {
  const { embedConfig } = React.useContext(EmbedDataContext);
  const analytics = useAnalytics();
  const currentLanguage = useLanguage();

  const enableDevLanguage = embedConfig !== null && embedConfig.enable_dev_language;

  return (
    <CommonUiLanguagePicker
      label={<Localized id="component.languagepicker.language.title">Language</Localized>}
      value={currentLanguage}
      trigger={trigger}
      onChange={async (lang) => {
        if (changeLocaleMutation !== undefined) {
          await changeLocaleMutation(lang);
        }

        if (i18n.isInitialized) {
          await i18n.changeLanguage(lang);
        }

        if (analytics) {
          analytics.track('Language changed', { previousLanguage: currentLanguage, currentLanguage: lang });
        }
      }}>
      {Object.keys(supportedLanguages).map((lang) => {
        return <LanguagePickerItem key={lang} value={lang} label={supportedLanguages[lang]} />;
      })}
      {enableDevLanguage && <LanguagePickerItem value="cimode" label="Dev language" />}
    </CommonUiLanguagePicker>
  );
};
